<template>
  <div class="date-and-time">
    <div class="date-and-time-left">
      <m-page-title :title="titleStr" address />
      <el-form
        :model="dateForm"
        ref="dateForm"
        label-position="top"
        size="medium"
      >
        <el-form-item label="Select date*">
          <el-date-picker
            v-model="dateForm.day"
            class="date-box"
            type="date"
            placeholder="DD/MM/YYYY"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions1"
            @change="datePicker"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Select time*">
          <el-popover
            :visible-arrow="false"
            v-model="selectTimeVisible"
            placement="bottom"
            trigger="click"
          >
            <div class="select-time-box">
              <!--              <div class="select-time-top">-->
              <!--                <div @click="resetTime" class="reset-btn">Reset</div>-->
              <!--                <div class="time-data">-->
              <!--                  <img src="@/static/icon/Time.png" alt="" />-->
              <!--                  {{ dateForm.time }}-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="select-time-content">
                <div
                  @click="selectTime(idx)"
                  class="select-time-item"
                  :class="[
                    idx === timeIndex ? 'active' : '',
                    dateForm.type === 2
                      ? ''
                      : (dateForm.day === beforeDateForm.day &&
                          time.startTime === beforeDateForm.startTime &&
                          time.endTime === beforeDateForm.endTime) ||
                        time.isCanAppointment
                      ? ''
                      : 'disabled',
                  ]"
                  v-for="(time, idx) in timeData"
                  v-bind:key="idx"
                >
                  {{ time.startTime | dateVal }} - {{ time.endTime | dateVal }}
                </div>
              </div>
              <el-button
                class="claim-time-btn"
                type="primary"
                @click="claimTime"
                >Done</el-button
              >
              <el-button class="claim-time-btn cancel" @click="timeSlotCancel"
                >Cancel</el-button
              >
            </div>
            <el-input
              placeholder="00:00 - 00:00"
              slot="reference"
              v-model="dateForm.time"
              readonly
            >
              <img
                class="time-icon"
                slot="suffix"
                src="@/static/icon/Time.png"
                alt=""
              />
            </el-input>
          </el-popover>
        </el-form-item>
        <el-form-item label="Phone">
          <el-input v-model="dateForm.phone" maxlength="20" />
        </el-form-item>
        <el-form-item label="Email">
          <el-input v-model="dateForm.email" maxlength="100" />
        </el-form-item>
        <el-form-item label="Comment">
          <el-input
            v-model="dateForm.comment"
            type="textarea"
            maxlength="1000"
          />
        </el-form-item>
        <div class="line flex-row">
          <m-back class="link" />
          <el-button
            :disabled="nextDisabled"
            class="action-btn"
            type="primary"
            @click="successPop"
            >Proceed to reschedule</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="date-and-time-right" v-if="dateForm.type !== 2">
      <pay-total
        :option-type="optionType"
        :date="dateForm.day"
        :time="dateForm.time"
        :moneyList="moneyList"
        :apartment-id="dateForm.apartmentId"
        :apartment-unit-id="dateForm.apartmentUnitId"
        :total="total"
      />
    </div>
    <m-dialog
      v-model="dialogShow"
      mask
      unBack
      confirm-txt="yes"
      cancel-txt="no"
      :title="successTittle"
      :content="contentTxt"
      :image="dialogImg"
      @confirm="confirmPopup"
      @cancel="cancelPopup"
    ></m-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import PayTotal from "@/components/PayTotal";
import { dateVal, regex } from "@/utils/tools";
export default {
  name: "",
  components: { PayTotal },
  data() {
    return {
      optionType: 2, //1.booking ,2.change
      advanceHour: 0,
      successTittle: "",
      selectTimeVisible: false,
      timeIndex: "",
      timeData: [],
      dialogShow: false,
      contentTxt: "",
      total: "0",
      moneyListDefault: [
        {
          label: "Subtotal（GST Exclude）",
          value: "0",
        },
        {
          label: "GST",
          value: "0",
        },
        {
          label: "Surcharge",
          value: "0",
        },
      ],
      moneyList: undefined,
      beforeDateForm: {},
      dateForm: {
        day: "",
        time: "",
        startTime: "",
        endTime: "",
        type: undefined,
        phone: "",
        email: "",
        comment: "",
      },
      orderId: "",
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      dialogImg: undefined,
    };
  },
  computed: {
    ...mapState("bar", ["sideBarStatus"]),
    ...mapGetters("apartment", ["addressStr"]),
    ...mapState("apartment", ["apartment", "room"]),
    ...mapState("user", ["userInfo"]),
    titleStr() {
      //判断标题的展示
      switch (this.dateForm.type) {
        case 0:
          return "Update Your Move In";
        case 1:
          return "Update Your Move Out";
        case 2:
          return "Select Date & Time";
        default:
          return "Update Your Move In";
      }
    },
    nextDisabled() {
      if (
        !this.dateForm.day ||
        !this.dateForm.time ||
        !regex.phone(this.dateForm.phone) ||
        !regex.email(this.dateForm.email)
      ) {
        console.log("this.dateForm", this.dateForm);
        return true;
      }
      return false;
    },
  },
  filters: {
    dateVal: function (val) {
      if (parseInt(val.substring(0, 2)) > 12) {
        return val + "pm";
      } else {
        return val + "am";
      }
    },
  },
  created() {
    this.dateForm.type = this.$route.query.orderTypeId;
    this.orderId = this.$route.query.orderId;
    this.moneyList = [...this.moneyListDefault];
    this.dataInfo();
    this.keyupSubmit();
  },
  methods: {
    ...mapMutations("booking", ["setReduction", "setModifyBookingData"]),
    // enter事件
    keyupSubmit() {
      document.onkeydown = () => {
        const _key = window.event.keyCode;
        if (_key === 13) {
          if (this.dialogShow) {
            this.confirmPopup();
            return;
          }
          if (this.dateForm.time || this.dateForm.day) {
            this.successPop();
            return;
          }
        }
      };
    },
    cancelPopup() {
      this.dialogShow = false;
    },
    async queryAdvanceHour(apartmentId, apartmentUnitId, apartmentFloorId) {
      //请求当前apartment设定时间
      let that = this;
      let params = { apartmentUnitId, apartmentFloorId };
      await that.$axios
        .get(this.$api.getAdvanceHour(apartmentId), { params })
        .then((res) => {
          this.advanceHour = res.data.advanceHour;
        });
      this.getCanBookingDate();
    },
    // 日历时间
    getCanBookingDate() {
      const curTime = new Date();
      if (curTime.getHours() + this.advanceHour > 23) {
        const dateTime = curTime.setHours(
          curTime.getHours() + this.advanceHour
        );
        this.pickerOptions1 = {
          disabledDate(time) {
            return time.getTime() < dateTime;
          },
        };
      }
    },
    dataInfo() {
      //获取订单详情
      let that = this;
      this.$axios.get(this.$api.orderInfo(this.orderId)).then((res) => {
        if (res.code === "1000") {
          const { apartmentOrder } = res.data;
          that.dateForm = Object.assign({}, apartmentOrder);
          that.beforeDateForm = Object.assign({}, apartmentOrder);
          that.dateForm.time =
            apartmentOrder.startTime + "-" + apartmentOrder.endTime;
          that.initDatePicker(that.dateForm.day);
          this.getApartmentIno(apartmentOrder.apartmentId);
          if (apartmentOrder.type !== 2) {
            this.queryAdvanceHour(
              this.room.apartmentId,
              this.room.apartmentUnitId,
              this.room.apartmentFloorId
            );
          }
          if (!that.dateForm.phone) {
            that.dateForm.phone = this.userInfo.boundPhone;
          }
          if (!that.dateForm.email) {
            that.dateForm.email = this.userInfo.email;
          }
        }
      });
    },
    confirmPopup() {
      this.facilityBooking();
    },
    successPop() {
      const that = this;
      let tips = "";
      switch (that.dateForm.type) {
        case 2:
          tips = "Communal Facility";
          break;
        case 0:
          tips = "Move In";
          break;
        default:
          tips = "Move Out";
          break;
      }
      that.successTittle = "Reschedule " + tips;
      that.contentTxt =
        "Do you want to re-schedule this booking?" +
        that.beforeDateForm.day +
        " " +
        that.beforeDateForm.startTime +
        "-" +
        that.beforeDateForm.endTime +
        " to " +
        that.dateForm.day +
        " " +
        that.dateForm.startTime +
        "-" +
        that.dateForm.endTime +
        "";
      that.dialogShow = true;
    },
    facilityBooking() {
      //修改订单时间
      if (parseFloat(this.total) > 0) {
        this.$axios
          .put(this.$api.updateComment(this.dateForm.id), {
            comment: this.dateForm.comment,
            apartmentFloorId: this.room.apartmentFloorId,
            apartmentNumberId: this.room.id,
          })
          .then((res) => {
            if (res.code === "1000") {
              this.$router.push({
                path: "/pay",
                query: {
                  orderId: this.dateForm.id,
                  feeType: 1, //0免费 or 1付费
                  optionType: this.optionType,
                },
              });
            }
          });
      } else {
        let params = {};
        params.orderId = this.dateForm.id; //订单编号
        params.phone = this.dateForm.phone;
        params.email = this.dateForm.email;
        params.day = this.dateForm.day; //选择日期
        params.startTime = this.dateForm.startTime; //选择开始时间
        params.endTime = this.dateForm.endTime; //选择结束时间
        params.comment = this.dateForm.comment;
        params.apartmentFloorId = this.room.apartmentFloorId;
        params.apartmentNumberId = this.room.id; //房间号
        this.$axios.put(this.$api.updateOrder, params).then((res) => {
          if (res.code === "1000") {
            this.$notify.success(
              "Congratulation! You've successfully reschedule your appointment"
            );
            //提交成功后去我的订单
            this.setReduction();
            this.$router.push("/myBooking");
          }
        });
      }
    },
    initDatePicker(date) {
      //获取选择日期后的可选时间
      let that = this;
      that.$set(that.dateForm, "day", date);
      if (this.dateForm.type === 2) {
        //免费
        that.$axios
          .get(this.$api.selTimes, {
            params: {
              apartmentAreaId: that.dateForm.apartmentAreaId, //公共设备id
              date: date, //选择日期
            },
          })
          .then((res) => {
            if (res.code === "1000") {
              for (var i = 0; i < res.data.list.length; i++) {
                res.data.list[i].isCanAppointment = true;
              }
              that.timeData = res.data.list;
            }
          });
      } else if (this.dateForm.type === 0 || this.dateForm.type === 1) {
        //搬家
        const url = `${that.$api.getMoveFees(
          that.dateForm.apartmentId
        )}?apartmentUnitId=${that.dateForm.apartmentUnitId}&apartmentFloorId=${
          that.dateForm.apartmentFloorId
        }&date=${date}`;
        that.$axios.get(url).then((res) => {
          if (res.code === "1000") {
            that.timeData = res.data.list;
            that.timeIndex = that.timeData.findIndex(
              (x) =>
                x.startTime === that.dateForm.startTime &&
                x.endTime === that.dateForm.endTime
            );
            that.selectTime(that.timeIndex);
          }
        });
      }
    },
    datePicker(date) {
      //获取选择日期后的可选时间
      const that = this;
      that.$set(that.dateForm, "day", date);
      if (this.dateForm.type === 2) {
        //免费
        that.$axios
          .get(this.$api.selTimes, {
            params: {
              apartmentAreaId: that.dateForm.apartmentAreaId, //公共设备id
              date: date, //选择日期
            },
          })
          .then((res) => {
            if (res.code === "1000") {
              that.timeData = res.data.list;
            }
          });
      } else if (this.dateForm.type === 0 || this.dateForm.type === 1) {
        //搬家
        const url = `${that.$api.getMoveFees(
          that.dateForm.apartmentId
        )}?apartmentUnitId=${that.dateForm.apartmentUnitId}&apartmentFloorId=${
          that.dateForm.apartmentFloorId
        }&date=${date}`;
        that.$axios.get(url).then((res) => {
          if (res.code === "1000") {
            that.timeIndex = "";
            that.$set(that.dateForm, "time", "");
            that.$set(that.dateForm, "startTime", "");
            that.$set(that.dateForm, "endTime", "");
            that.timeData = res.data.list;
          }
        });
      }
    },
    resetTime() {
      //重置时间
      this.timeIndex = "";
      this.dateForm.time = "";
    },
    timeSlotCancel() {
      //this.selectTimeVisible = false;
      this.resetTime();
    },
    selectTime(v) {
      const dataModel = this.timeData[v];
      if (
        this.dateForm.type !== 2 &&
        (this.dateForm.day !== this.beforeDateForm.day ||
          dataModel.startTime !== this.beforeDateForm.startTime ||
          dataModel.endTime !== this.beforeDateForm.endTime) &&
        !dataModel.isCanAppointment
      ) {
        return;
      }
      //选择时间
      this.timeIndex = v;
      this.dateForm.time =
        dateVal(dataModel.startTime) + "-" + dateVal(dataModel.endTime);
      this.dateForm.startTime = dataModel.startTime;
      this.dateForm.endTime = dataModel.endTime;
      if (this.dateForm.type === 1 || this.dateForm.type === 0) {
        let params = {};
        params.orderId = this.dateForm.id; //订单编号
        params.day = this.dateForm.day; //选择日期
        params.startTime = this.dateForm.startTime; //选择开始时间
        params.endTime = this.dateForm.endTime; //选择结束时间
        this.$axios.put(this.$api.differenceBalance, params).then((res) => {
          if (res.code === "1000") {
            const { difference, taxes, creditCardSurcharge } = res.data;
            if (difference > 0) {
              params.type = this.beforeDateForm.type;
              params.totalAmt = difference;
              params.creditCardSurcharge = creditCardSurcharge;
              params.taxes = taxes;
              params.preDay = this.beforeDateForm.day;
              params.preStartTime = this.beforeDateForm.startTime;
              params.preEndTime = this.beforeDateForm.endTime;
              //差额
              this.moneyList = [
                {
                  label: "Subtotal（GST Exclude）",
                  value: `${(difference - taxes - creditCardSurcharge)
                    .toFixed(2)
                    .toString()}`,
                },
                {
                  label: "GST",
                  value: `${taxes.toFixed(2).toString()}`,
                },
                {
                  label: "Surcharge",
                  value: `${creditCardSurcharge.toFixed(2).toString()}`,
                },
              ];
              //总金额
              this.total = difference.toFixed(2).toString();
              this.setModifyBookingData(params);
            } else {
              this.moneyList.splice(
                0,
                this.moneyList.length,
                ...this.moneyListDefault
              );
              this.total = "0";
            }
          }
        });
      }
    },
    claimTime() {
      //关闭选择时间
      this.selectTimeVisible = false;
    },
    backClick() {
      //返回
      this.$router.back();
    },
    getApartmentIno(apartmentId) {
      this.$axios.get(this.$api.getApartmentIno(apartmentId)).then((res) => {
        this.dialogImg = res.data.photo;
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
